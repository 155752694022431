<template>
  <a-modal :closable="true" centered @cancel="cancel" v-model="visable" :footer="null" class="captcha-modal">
    <div style="text-align: center">
      <h1 style="margin-bottom: 30px">{{ $t('user.bindPhone') }}</h1>
    </div>
    <form @submit.prevent>
      <div class="info-item">
        <span class="name">{{ $t('user.phone') }}</span>
        <div class="label">
          <a-select ref="select" v-model="areaCode" style="width: 140px; margin-right: 5px">
            <a-select-option :value="item.code" v-for="item in areaCodeList" :key="item.code">{{ item.code + ' ' + item.cn }}</a-select-option>
          </a-select>
          <input style="display: inline; width: 170px; height: 32px" type="text" v-model="data.phone" autocomplete="off" />
        </div>
      </div>
      <div class="info-item">
        <span class="name">{{ $t('user.code') }}</span>
        <div class="label">
          <input type="text" v-model="data.code" style="width: 215px; height: 32px" autocomplete="off" />
          <button :disabled="sent" class="button button-mini" style="margin-left: 4px; vertical-align: top; height: 32px" @click="handleSendSMS">{{ sent ? t + 's' : $t('user.sendCode') }}</button>
        </div>
      </div>

      <div style="text-align: center">
        <a href="javascript:;" style="width: 79%" class="button" @click="handleBindPhone">{{ $t('common.submit') }}</a>
      </div>
    </form>
  </a-modal>
</template>
<script>
import Emitter from 'mixins/emitter';
import areaCodeList from 'utils/areaCode.json';
import { mapGetters } from 'vuex';
import { checkPhone, sendSMS } from 'services/user';

export default {
  name: 'CheckPhoneModal',
  props: {
    modalShowAble: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data() {
    return {
      visable: false,
      data: {
        phone: '',
        code: '',
      },
      areaCode: '+86',
      sent: false,
      t: 0,
      areaCodeList,
    };
  },
  mixins: [Emitter],
  computed: {
    ...mapGetters(['userInfo']),
  },
  watch: {
    modalShowAble(val) {
      this.visable = this.modalShowAble;
      this.data.phone = this.userInfo ? this.userInfo.phone : '';
    },
  },

  methods: {
    cancel() {
      this.$emit('changeModalData', 'false', 'false');
    },
    handleBindPhone() {
      if (!this.data.phone || !this.data.code) {
        this.$message.error(this.$t('check.nonePhoneOrCode'));
        return;
      }
      if (this.areaCode === '+86' && !/^1[3456789]\d{9}$/.test(this.data.phone)) {
        this.$message.error(this.$t('check.errorPhone'));
        return;
      }
      checkPhone({
        phone: this.areaCode === '+86' ? this.data.phone : this.areaCode + this.data.phone + '',
        code: this.data.code,
      })
        .then(res => {
          this.$store.dispatch('initAuth');
          this.$emit('changeModalData', 'false', 'true');
        })
        .catch(err => {
          this.$error({
            title: this.$t(`error['${err.response.data.errorKey}']`),
          });
          if (err.response.status === 403) {
            this.$emit('userUpdated', 'false');
            this.dispatch('account', 'error', [err]);
          }
        });
    },
    handleSendSMS() {
      if (this.areaCode === '+86' && (!this.data.phone || !/^1[3456789]\d{9}$/.test(this.data.phone))) {
        this.$message.error(this.$t('check.errorPhone'));
        return;
      }
      this.sent = true;
      this.t = 60;
      let interval = setInterval(() => {
        if (this.t > 0) {
          this.t--;
        } else {
          this.sent = false;
          clearInterval(interval);
        }
      }, 1000);
      sendSMS({
        phone: this.areaCode === '+86' ? this.data.phone : this.areaCode + this.data.phone + '',
      }).then(res => {
        if (res.code === 0) {
          this.$success({
            title: this.$t('check.SMSSuccess'),
          });
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
@import '../assets/css/theme.less';

.info-item {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
  margin-left: 20px;

  .name {
    width: 72px;
    text-align: right;
    overflow: hidden;
    display: inline-block;
    color: #999;
    padding-top: 5px;

    &:after {
      content: '';
      display: inline-block;
      width: 100%;
    }
  }

  .label {
    margin-left: 10px;
    span {
      padding-top: 5px;
      padding-left: 10px;
      display: inline-block;
    }
  }

  input {
    border: 1px solid #ddd;
    padding: 5px 10px;
    border-radius: 3px;
    width: 296px;

    &:focus {
      border-color: #bbb;
    }
  }
}
</style>
