<template>
  <div id="nc-container" class="nc-container"></div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
  name: 'ali-nc',
  data() {
    return {
      nc: null,
    };
  },
  computed: {
    ...mapGetters(['locale']),
  },
  mounted() {
    this.script = document.createElement('script');
    this.script.type = 'text/javascript';
    this.script.src = '//g.alicdn.com/sd/ncpc/nc.js?t=2015052012';
    document.body.appendChild(this.script);
    let lang;
    if (this.locale === 'zh_CN') {
      lang = 'cn';
    } else {
      lang = 'en';
    }
    this.script.onload = () => {
      var nc_token = ['FFFF0N00000000008AF8', new Date().getTime(), Math.random()].join(':');
      var NC_Opt = {
        renderTo: '#nc-container',
        appkey: 'FFFF0N00000000008AF8',
        scene: 'nc_login',
        token: nc_token,
        customWidth: '100%',
        trans: { key1: 'code0' },
        elementID: ['usernameID'],
        is_Opt: 0,
        language: lang,
        isEnabled: true,
        timeout: 3000,
        times: 5,
        apimap: {},
        callback: data => {
          this.$emit('callback', data);
          /* this.user.sig = data.sig;
                        this.user.sessionId = data.csessionid;
                        this.user.token = data.token; */
        },
      };
      try {
        this.nc = new noCaptcha(NC_Opt);
        // this.nc.upLang('cn', {
        //     _startTEXT: this.$t('user.ncStartText'),
        //     _yesTEXT: this.$t('user.ncYesText'),
        //     _error300: '哎呀，出错了，点击<a href="javascript:__nc.reset()\">刷新</a>再来一次',
        //     _errorNetwork: '网络不给力，请<a href=\"javascript:__nc.reset()\">点击刷新</a>',
        // })
      } catch (err) {
        this.nc = new noCaptcha(NC_Opt);
        // this.nc.upLang('cn', {
        //     _startTEXT: this.$t('user.ncStartText'),
        //     _yesTEXT: this.$t('user.ncYesText'),
        //     _error300: '哎呀，出错了，点击<a href="javascript:__nc.reset()\">刷新</a>再来一次',
        //     _errorNetwork: '网络不给力，请<a href=\"javascript:__nc.reset()\">点击刷新</a>',
        // })
      }
    };
  },
  beforeDestroy() {
    this.script.parentNode.removeChild(this.script);
  },
  methods: {
    reset() {
      this.nc.reset();
    },
  },
};
</script>
<style lang="less" scoped>
@import '../assets/css/theme.less';

@height: 40px;
.nc-container {
  height: @height;

  #nc_1_wrapper {
    width: 100%;
  }

  /deep/ .nc_scale {
    .nc_bg {
      background-color: @primary-color;
    }

    height: @height;

    span {
      height: @height;
      line-height: @height;
    }

    .nc-lang-cnt {
      color: #444;
    }

    .btn_ok {
      color: @primary-color;
      border-color: @primary-color;
    }
  }
}
</style>
